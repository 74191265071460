const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '600596bf05be60add9732565',
    disabledComponents: ['wButtonLike'],
    showCardDescription: true,
    enableExperimentalBlocks: true,
    authMode: [
      'local'
    ],
    authLocalOptions: {
      mode: 'otp',
    },
    authLocaleLoginFormat: ['phone'],
    disabledComponents: ['wButtonLike'],
    enableExperimentalBlocks: true,
    metadatas: {
      name: `QUICK, L’émission`,
      colorPrimary: '#C8102E',
      supportEmailAddress: 'contact@quick-emission.fr',
    },
    analytics: {
      matomo:'5'
    },
    useShell: ['bb-default', {
      mode: 'solo',
      contentId: '60993574ab282e509d58a7d0'
    }],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)