<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container>
      <v-sheet color="primary" dark class="pa-3">
        <div class="subtitle-1">
          <strong>
            Votre première émission : donnez votre avis, c’est par
          </strong>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScsv8UeaSNRKwhJDZS4rWiJJOyUuQ10fUB-UB8wpyNUsYn9ng/viewform

"
            target="_blank"
            >ici</a
          >
          !
        </div>
      </v-sheet>
    </v-container>
    <w-block name="cta" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
}
a {
  font-weight: bold;
  color: white;
  text-decoration: underline;
}
</style>
